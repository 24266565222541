import {
    Button,
    Form,
    Modal,
    Space,
    Alert,
    Select,
} from "antd";
import { useEffect } from "react";
import { UserDto } from "@shared/types/user/user.dto";

interface IModal {
    open: boolean;
    user: UserDto | null;
    onClose: () => void;
    onSave: (data: UserDto) => void;
    isLoading: boolean;
    isError?: boolean;
}

const ChangeUserModal = ({
    open,
    user,
    onClose,
    onSave,
    isLoading,
    isError,
}: IModal) => {
    const isEditingMode = user?.id;

    const [form] = Form.useForm();

    useEffect(() => {
        if (user) {
            const formattedPlayer = {
                ...user,
            };

            form.setFieldsValue(formattedPlayer);
        }
    }, [form, user]);

    const handleFinish = (values: UserDto) => {
        const formattedValues = {
            ...values,
        };
        onSave(formattedValues);
    };

    return (
        <Modal
            title={'Change User'}
            open={open}
            onCancel={onClose}
            footer={null}
        >
            <Form
                key={JSON.stringify(user)}
                form={form}
                requiredMark={false}
                onFinish={handleFinish}
            >
                {isError && (
                    <Form.Item>
                        <Alert
                            message="Error"
                            description="An error occurred while saving the user data. Please try again."
                            type="error"
                            showIcon
                        />
                    </Form.Item>
                )}

                <Form.Item
                    name="dailyDraftsCounter"
                    label="Drafts Played"
                    labelCol={{ span: 24, style: { padding: 0 } }}
                    style={{ margin: 0 }}
                    rules={[
                        { type: "number", message: "Daily draft counter must be a number", required: true },
                    ]}
                >
                    <Select options={[
                        { label: "0", value: 0 },
                        { label: "1", value: 1 },
                        { label: "2", value: 2 },
                        { label: "3", value: 3 },
                    ]} />
                </Form.Item>

                <Space style={{ justifyContent: "flex-end", width: "100%", marginTop: 10 }}>
                    <Form.Item>
                        <Button type="default" onClick={onClose}>
                            Cancel
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {isEditingMode ? "Save" : "Add"}
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    );
};

export default ChangeUserModal;
