export enum ErrorKeysEnum {
    NO_INTERNET = "No_Internet",
    SERVICE_UNAVAILABLE = "Service_Unavailable",
    ERROR = "Error",
    BAD_REQUEST = "BadRequest",
}

export interface HttpErrorResponseQuery {
    errors: any;
    correlationId?: string;
    status: number;
}

export class HttpErrorResponse {
    public errors: Map<ErrorKeysEnum, Array<string>>;
    public correlationId?: string;
    public status: number;

    static createHttpErrorResponse(data: unknown): HttpErrorResponse {
        return new HttpErrorResponse(data as HttpErrorResponseQuery);
    }

    constructor(data: HttpErrorResponseQuery) {
        this.update(data);
    }

    update(newData: HttpErrorResponseQuery) {
        this.correlationId = newData.correlationId;
        this.status = newData.status;
        if (!newData.errors || typeof newData.errors !== "object") {
            newData.errors = {
                [ErrorKeysEnum.ERROR]: "Unhandled error",
            };
        }
        if (newData.errors instanceof Object) {
            this.errors = Object.keys(newData.errors).reduce((result, key) => {
                result.set(key as ErrorKeysEnum, newData.errors[key]);
                return result;
            }, new Map<ErrorKeysEnum, Array<string>>());
        }
        return this;
    }

    findErrorMessageByKey(key: ErrorKeysEnum): string | null {
        const messageArray = this.errors.get(key);
        if (messageArray && typeof messageArray[0] === "string") {
            return messageArray[0];
        } else {
            return null;
        }
    }

    findFirstErrorMessage(): string {
        const messageArray = Array.from(this.errors.values())[0];
        return Array.isArray(messageArray) && messageArray[0]
            ? messageArray[0]
            : "";
    }

    hasError(errorKey: ErrorKeysEnum): boolean {
        return this.errors.has(errorKey);
    }
}
