import Axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios";
import { appInjectable } from "@core/di/utils";
import {
    HttpConfig,
    HttpFailResponse,
    HttpInstance,
    HttpRequestConfig,
    HttpSuccessResponse,
    IHttpClientService,
} from "@shared/interfaces/http-client-service.interface";
import { browser } from "@shared/utils/browser";
import {
    ErrorKeysEnum,
    HttpErrorResponse,
} from "@shared/models/error/http-error-response";
import { parseArrayBuffer } from "@shared/utils/binary";
import { TokenRefreshStatus } from "@shared/constants/auth";

@appInjectable()
export class HttpClientService implements IHttpClientService {
    private _client: HttpInstance;
    private getAccessToken?: HttpConfig["getAccessToken"];
    private refreshToken?: HttpConfig["refreshToken"];
    private getTokenRefreshStatus?: HttpConfig["getTokenRefreshStatus"];

    constructor() {
        this._client = this.createClient();
        this.setClientResponseInterceptor();
        this.setClientRequestInterceptor();
    }

    setConfig: IHttpClientService["setConfig"] = (config) => {
        this.setClientConfig(config.defaults);
        this.refreshToken = config.refreshToken;
        this.getAccessToken = config.getAccessToken;
        this.getTokenRefreshStatus = config.getTokenRefreshStatus;
    };

    async get<T = unknown>(
        url: string,
        config?: HttpRequestConfig,
    ): Promise<HttpSuccessResponse<T>> {
        return this._client.get<T>(url, config);
    }

    post<T = unknown>(
        url: string,
        body?: unknown,
        config?: HttpRequestConfig,
    ): Promise<HttpSuccessResponse<T>> {
        return this._client.post<T>(url, body, config);
    }

    put<T = unknown>(
        url: string,
        body?: unknown,
        config?: HttpRequestConfig,
    ): Promise<HttpSuccessResponse<T>> {
        return this._client.put<T>(url, body, config);
    }

    patch<T = unknown>(
        url: string,
        body?: unknown,
        config?: HttpRequestConfig,
    ): Promise<HttpSuccessResponse<T>> {
        return this._client.patch<T>(url, body, config);
    }

    delete<T = unknown>(
        url: string,
        config?: HttpRequestConfig,
    ): Promise<HttpSuccessResponse<T>> {
        return this._client.delete<T>(url, config);
    }

    generateCancelToken: IHttpClientService["generateCancelToken"] = () => {
        return Axios.CancelToken.source();
    };

    private createClient = () => {
        return Axios.create();
    };

    private get authHeader() {
        if (this.getAccessToken) {
            const accessToken = this.getAccessToken();

            return `Bearer ${accessToken}`;
        }

        return undefined;
    }

    private setClientConfig(defaults?: HttpConfig["defaults"]) {
        if (defaults) {
            this.setDefaults(defaults);
        }
    }

    private setDefaults(defaults: HttpRequestConfig) {
        this._client.defaults.baseURL = defaults.baseURL;

        (this._client.defaults as { headers: unknown }).headers = {
            "Content-Type": "application/json",
        };

        if (browser?.name === "ie") {
            this._client.defaults.headers.Pragma = "no-cache";
        }
    }

    private handleUnauthenticated = async (
        response: HttpSuccessResponse<unknown>,
    ) => {
        if (this.refreshToken) {
            await this.refreshToken();
        }

        if (!this.authHeader) {
            return;
        }

        response.config.headers.Authorization = this.authHeader;

        return this._client(response.config);
    };

    private setClientResponseInterceptor() {
        this._client.interceptors.response.use(
            (response: HttpSuccessResponse<{ data: unknown }>) => {
                return { ...response, data: response.data };
            },
            async (error: HttpFailResponse<unknown>) => {
                if (!navigator.onLine) {
                    throw HttpErrorResponse.createHttpErrorResponse({
                        errors: {
                            [ErrorKeysEnum.NO_INTERNET]: "No or Poor internet connection",
                        },
                    });
                }

                const response = error ? error.response : undefined;

                if (!response) {
                    throw HttpErrorResponse.createHttpErrorResponse({
                        error: "No Respone",
                    });
                }

                if (response.status === 401) {
                    this.handleUnauthenticated(response);
                }

                if (response.status === 503) {
                    throw HttpErrorResponse.createHttpErrorResponse({
                        errors: {
                            [ErrorKeysEnum.SERVICE_UNAVAILABLE]:
                                "Service temporary unavailable",
                        },
                    });
                }

                if (response.status === 409) {
                    throw HttpErrorResponse.createHttpErrorResponse({
                        errors: response.data
                    });
                }

                let responseData = response.data;

                if (error?.request?.responseType === "arraybuffer") {
                    responseData = parseArrayBuffer(response.data as ArrayBuffer);
                }

                if (
                    !responseData ||
                    !Object.prototype.hasOwnProperty.call(responseData, "status")
                ) {
                    throw HttpErrorResponse.createHttpErrorResponse({
                        status: response.status,
                    });
                } else {
                    throw HttpErrorResponse.createHttpErrorResponse(responseData);
                }
            },
        );
    }

    private setClientRequestInterceptor() {
        this._client.interceptors.request.use(
            (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
                if (!this.getAccessToken || !this.getTokenRefreshStatus) {
                    return config;
                }

                const accessToken = this.getAccessToken();

                if (
                    !accessToken ||
                    this.getTokenRefreshStatus() === TokenRefreshStatus.refreshing
                ) {
                    return config;
                }

                (config.headers as AxiosRequestHeaders).Authorization = this.authHeader;

                return config;
            },
            (error: Error) => {
                throw error;
            },
        );
    }
}
