import { appInject, appInjectable } from "@core/di/utils";
import { BaseService } from "@core/services/base";
import { DI_TOKENS } from "@shared/constants/di";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { IUserService } from "@shared/interfaces/user.interface";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import { UserListPaginatedDto } from "@shared/types/user/user-list-paginated.dto";
import { UserDto } from "@shared/types/user/user.dto";

@appInjectable()
export class UserService extends BaseService implements IUserService {
    private configService = appInject<IConfigService>(DI_TOKENS.configService);
    private httpClient = appInject<IHttpClientService>(
        DI_TOKENS.httpClientService,
    );
    private baseURL: string;

    constructor() {
        super();
        this.baseURL = this.configService.baseUrl;
    }

    async update(dto: UserDto): Promise<UserDto> {
        const { data } = await this.httpClient.patch<UserDto>(
            `/admin/update-user/${dto.id}`,
            {
                ...dto
            },
            {
                baseURL: this.baseURL,
            },
        );

        return data;
    }

    async getUsersList(dto: {
        page?: number;
        pageSize?: number;
        disabled?: string;
    }): Promise<UserListPaginatedDto> {
        const { data } = await this.httpClient.get<UserListPaginatedDto>(
            "/user/list",
            {
                params: dto,
                baseURL: this.baseURL,
            },
        );

        return data;
    }

    async reDealCards(dto: { id: string }): Promise<UserDto> {
        const { data } = await this.httpClient.post<UserDto>(
            `/admin/re-deal-cards/${dto.id}`,
            {},
            {
                baseURL: this.baseURL,
            },
        );

        return data;
    }

    async resetUserAccount(dto: { id: string }): Promise<UserDto> {
        const { data } = await this.httpClient.post<UserDto>(
            `/user/reset-data/${dto.id}`,
            {},
            {
                baseURL: this.baseURL,
            },
        );

        return data;
    }
}
